import { createApp } from "vue";

import App from "./App.vue";
import { router } from "./router";
import { store } from "./store";
import { i18n } from "@/i18n";

import globalDirectives from "@/directives";

import api from "./plugins/api";
import primaryNavItems from "@/plugins/primaryNavItems";
import getAccountShortName from "@/plugins/getAccountShortName";
import { Vue3ProgressPlugin } from "@marcoschulte/vue3-progress";
import "@marcoschulte/vue3-progress/dist/index.css";
import jsonToCsv from "./plugins/jsonToCsv";
import imageUrl from "./plugins/imageUrl";
import secondaryNavItems from "@/plugins/secondaryNavItems";
import vueCountryRegionSelect from "vue3-country-region-select";
import VueDOMPurifyHTML from "vue-dompurify-html";
import Multiselect from "vue-multiselect";
import logoutPlugin from "@/plugins/logout.js";
import * as Sentry from "@sentry/vue";
import { version } from "../package";

import {
  ClActiveFilter,
  ClDataTable,
  ClButton,
  ClBreadcrumbs,
  ClHeading,
  ClModal,
  ClSpinner,
  ClDropdown,
  ClDropdownItem,
  ClTooltip,
  ClCard,
  ClCardTrend,
  ClSidebar,
  ClIconTablerBriefcase,
  ClIconTablerEditCircle,
  ClIconTablerIconEye,
  ClIconTablerEdit,
  ClSidebarNav,
  ClSidebarNavItemParent,
  ClSidebarNavItemChild,
  ClIconTablerHistory,
  ClIconTablerArrowForkLeft,
  ClIconTablerReportAnalytics,
  ClIconTablerShieldCheck,
  ClIconTablerShieldLock,
  ClIconTablerDashboard,
  ClIconTablerSettings,
  ClTabs,
  ClTabsItem,
  ClSwitcher,
  ClIconTablerMail,
  ClCheckbox,
  ClToast,
  ClFormRadioGroup,
  ClFormInput,
  ClFormGroup,
  ClFormLabel,
  ClFormTextarea,
  ClFormSelect,
  ClIconTablerTrash,
  ClIconTablerArrowDown,
  ClIconTablerArrowUp,
  ClSkeleton,
  ClSkeletonRow,
  ClHr,
  ClHeaderDropdown,
  ClHeaderDropdownProduct,
  ClHeaderDropdownLink,
  ClIconTablerLogout,
  ClIconTablerLifebuoy,
  ClFooter,
  ClStepper,
  ClIconTablerCalendarEvent,
  ClTablerIconBuildingSkyscraper,
  ClTablerIconUsers,
  ClSidebarDropdown,
  ClIconTablerCircleCheck,
  ClDataTableEditAction,
  ClDataTableViewAction,
  ClPill,
  ClIconTablerAdjustmentsHorizontal,
  ClIconTablerForbid,
  ClIconTablerUserPlus,
  ClIconTablerMailCheck,
  ClDataTableMoreAction,
  ClDataTableMoreActionItem,
  ClIconTablerX,
  ClIconTablerCopy,
  ClIconTablerExternalLink,
} from "@titanhq/frontend-component-library";

const app = createApp(App);

Sentry.init({
  app,
  environment: "production",
  dsn: "https://f53d5c45f5dc1c23dddbedf4f358bc9e@o4507293832511488.ingest.us.sentry.io/4507293943136256",
  release: version,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in production and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(store);
app.use(router);
app.use(i18n);

app.use(VueDOMPurifyHTML);
app.use(imageUrl);
app.use(vueCountryRegionSelect);
app.use(primaryNavItems);
app.use(getAccountShortName);
app.use(Vue3ProgressPlugin);
app.use(api);
app.use(jsonToCsv);
app.use(secondaryNavItems);

localStorage.setItem("logoutFlag", "false");

app.use(logoutPlugin);

app.use(globalDirectives);

app.component("c-multiselect", Multiselect);
app.component("cl-data-table", ClDataTable);
app.component("cl-button", ClButton);
app.component("cl-breadcrumbs", ClBreadcrumbs);
app.component("cl-heading", ClHeading);
app.component("cl-modal", ClModal);
app.component("cl-spinner", ClSpinner);
app.component("cl-dropdown", ClDropdown);
app.component("cl-dropdown-item", ClDropdownItem);
app.component("cl-tooltip", ClTooltip);
app.component("cl-card", ClCard);
app.component("cl-card-trend", ClCardTrend);
app.component("cl-sidebar", ClSidebar);
app.component("cl-sidebar-nav", ClSidebarNav);
app.component("cl-sidebar-nav-item-parent", ClSidebarNavItemParent);
app.component("cl-sidebar-nav-item-child", ClSidebarNavItemChild);
app.component("cl-tabs", ClTabs);
app.component("cl-tabs-item", ClTabsItem);
app.component("cl-switcher", ClSwitcher);
app.component("cl-checkbox", ClCheckbox);
app.component("cl-toast", ClToast);
app.component("cl-form-radio-group", ClFormRadioGroup);
app.component("cl-form-input", ClFormInput);
app.component("cl-form-group", ClFormGroup);
app.component("cl-form-label", ClFormLabel);
app.component("cl-form-textarea", ClFormTextarea);
app.component("cl-form-select", ClFormSelect);
app.component("cl-skeleton", ClSkeleton);
app.component("cl-skeleton-row", ClSkeletonRow);
app.component("cl-hr", ClHr);
app.component("cl-header-dropdown", ClHeaderDropdown);
app.component("cl-header-dropdown-product", ClHeaderDropdownProduct);
app.component("cl-header-dropdown-link", ClHeaderDropdownLink);
app.component("cl-footer", ClFooter);
app.component("cl-stepper", ClStepper);
app.component("cl-sidebar-dropdown", ClSidebarDropdown);
app.component("cl-data-table-edit-action", ClDataTableEditAction);
app.component("cl-data-table-view-action", ClDataTableViewAction);
app.component("cl-data-table-more-action", ClDataTableMoreAction);
app.component("cl-data-table-more-action-item", ClDataTableMoreActionItem);
app.component("cl-pill", ClPill);
app.component("cl-active-filter", ClActiveFilter);

app.component("cl-icon-tabler-dashboard", ClIconTablerDashboard);
app.component("cl-icon-tabler-icon-edit-circle", ClIconTablerEditCircle);
app.component("cl-icon-tabler-icon-eye", ClIconTablerIconEye);
app.component("cl-icon-tabler-icon-edit", ClIconTablerEdit);
app.component("cl-icon-tabler-history", ClIconTablerHistory);
app.component("cl-icon-tabler-arrow-fork-left", ClIconTablerArrowForkLeft);
app.component("cl-icon-tabler-report-analytics", ClIconTablerReportAnalytics);
app.component("cl-icon-tabler-shield-check", ClIconTablerShieldCheck);
app.component("cl-icon-tabler-shield-lock", ClIconTablerShieldLock);
app.component("cl-icon-tabler-settings", ClIconTablerSettings);
app.component("cl-icon-tabler-mail", ClIconTablerMail);
app.component("cl-icon-tabler-trash", ClIconTablerTrash);
app.component("cl-icon-tabler-arrow-up", ClIconTablerArrowUp);
app.component("cl-icon-tabler-arrow-down", ClIconTablerArrowDown);
app.component("cl-icon-tabler-logout", ClIconTablerLogout);
app.component("cl-icon-tabler-lifebuoy", ClIconTablerLifebuoy);
app.component("cl-icon-tabler-calendar-event", ClIconTablerCalendarEvent);
app.component(
  "cl-icon-tabler-building-skyscraper",
  ClTablerIconBuildingSkyscraper
);
app.component("cl-icon-tabler-users", ClTablerIconUsers);
app.component("cl-icon-tabler-briefcase", ClIconTablerBriefcase);
app.component("cl-icon-tabler-circle-check", ClIconTablerCircleCheck);
app.component(
  "cl-icon-tabler-adjustments-horizontal",
  ClIconTablerAdjustmentsHorizontal
);
app.component("cl-icon-tabler-user-plus", ClIconTablerUserPlus);
app.component("cl-icon-tabler-mail-check", ClIconTablerMailCheck);
app.component("cl-icon-tabler-forbid", ClIconTablerForbid);
app.component("cl-icon-table-x", ClIconTablerX);
app.component("cl-icon-tabler-copy", ClIconTablerCopy);
app.component("cl-icon-tabler-external-link", ClIconTablerExternalLink);

app.config.devtools = true;

app.mount("#app");

export default {};
